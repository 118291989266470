import { useEffect, useState, type RefObject } from 'react';

export const useIsTruncated = (elementRef: RefObject<HTMLElement>) => {
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (!elementRef.current) {
      setIsTruncated(false);
      return;
    }

    const checkTruncation = () => {
      const element = elementRef.current;
      if (!element) return;

      const entryWidth = element.offsetWidth;
      const entryScrollWidth = element.scrollWidth;
      const entryHeight = element.offsetHeight;
      const entryScrollHeight = element.scrollHeight;

      const truncated = entryWidth < entryScrollWidth || entryHeight < entryScrollHeight;
      setIsTruncated(truncated);
    };

    // Initial check with a small delay just to be sure :)
    const timeoutId = setTimeout(checkTruncation, 0);

    const resizeObserver = new ResizeObserver(checkTruncation);
    resizeObserver.observe(elementRef.current);

    // Cleanup
    return () => {
      clearTimeout(timeoutId);
      resizeObserver.disconnect();
    };
  }, [elementRef]);

  return isTruncated;
};
